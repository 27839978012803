import React from "react"
import styled from "styled-components"

import Layout from "../layout"
import { Title } from "../components/styled"

import image1 from "../images/about/about1.png"
import image2 from "../images/about/about2.png"
import image3 from "../images/about/about3.png"
import image4 from "../images/about/about4.png"
import image5 from "../images/about/about5.png"

const Totem = styled.div`
  flex: 0 0 100px;
  margin: 0 30px;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 599px) {
    display: none;
  }
`
const Content = styled.div`
  padding: 0 50px;
`

const AboutPage = () => {
  return (
    <Layout title="About">
      <Totem>
        <img src={image1} />
        <img src={image2} />
        <img src={image3} />
        <img src={image4} />
        <img src={image5} />
      </Totem>
      <Content>
        <Title>Behind SlowPrint</Title>
        <p>
          I specialize in printed textiles and cotton papers with lino blocks and rubber blocks. All the printings are
          made by hand. Illustrations are fully carved in linoleum and then printed in a little studio based in
          Toulouse, France.
        </p>
        <p>
          The inspiration behind all the illustrations of these prints comes from dreams and life’s mysteries which are
          imperceivable in day to day life. It’s a personal universe manifested in a tangible form... a ride on the
          wings of another person’s imagination and maybe an invitation to discover yours.
        </p>
        <p>
          Zero printing in series, only by order, because taking our time is cool. Interested in collaborations in
          graphic and textile creations? Please contact me at{" "}
          <a href="mailto:isabel@slowprint.fr">isabel@slowprint.fr</a>.
        </p>
      </Content>
    </Layout>
  )
}

export default AboutPage
